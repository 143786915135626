import { useEffect, useState } from "react";
import { observer } from "mobx-react";
import { Seomatic } from "react-seomatic";
import Head from "next/head";
import SearchBar from "@/components/SearchBar";
import Chip from "@/components/Chip";
import Button from "@/components/Button";
import RecipeThumbnail from "@/src/recipes/components/Thumbnail";
import algoliaSearchClient from "@/lib/algoliaSearchClient";
import getCommonStaticProps from "@/lib/getCommonStaticProps";
import Query404NotFoundPage from "@/queries/Query404NotFoundPage.graphql";
import craft from "@/lib/craft";
import { INDEX_POPULARITY_DESC } from "@/constants/algoliaConstants";
import Link from "next/link";
import { useHeaderContext } from "@/contexts/HeaderContext";
import { useInView } from "react-intersection-observer";
import QuerySuggestions from "@/components/QuerySuggestions";
import ShoppingListContainer from "@/components/ShoppingList/ShoppingListContainer";

export async function getStaticProps({ previewData, preview }) {
  // Fetch common static properties
  const commonStaticProps = await getCommonStaticProps();

  const queries = [
    {
      document: Query404NotFoundPage,
    },
  ];

  const response = await craft(queries, preview ? previewData.token : null);

  const { pageEntry } = response[0];

  return {
    props: {
      ...commonStaticProps,
      title: pageEntry.textContent,
      subTitle: pageEntry.subTitle,
      seomatic: pageEntry.seomatic,
    },
    revalidate: process.env.VERCEL_ENV !== "production" ? 1 : 3600,
  };
}

const NotFound = ({ seomatic, title, subTitle }) => {
  const [selectedTerm, setSelectedTerm] = useState("");
  const [recipes, setRecipes] = useState([]);
  const { setIsHeroScrolledPast } = useHeaderContext();
  const [ref, inView] = useInView({
    threshold: 0,
    rootMargin: "-1px 0px 0px 0px",
  });

  // TODO update this to be a cached query
  const fetchTopRecipes = async (query = "") => {
    try {
      const index = algoliaSearchClient.initIndex(INDEX_POPULARITY_DESC);
      const { hits } = await index.search(query, {
        hitsPerPage: 12,
        attributesToRetrieve: [
          "id",
          "uid",
          "slug",
          "uri",
          "title",
          "image.id",
          "image.url",
          "sectionHandle",
          "chefs.id",
          "chefs.title",
          "chefs.slug",
          "chefs.image.id",
          "chefs.image.url",
          "limitAccessTo",
          "accessLevel",
          "dietaryRequirements.id",
          "dietaryRequirements.title",
          "time",
          "recipeCount",
          "postDate",
          "_tags",
        ],
      });
      setRecipes(hits);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    fetchTopRecipes();
  }, []);

  const toggleTermSelected = (term) => {
    if (term === selectedTerm) {
      setSelectedTerm("");
      fetchTopRecipes();
      return;
    }

    setSelectedTerm(term);
    fetchTopRecipes(term);
  };

  useEffect(() => {
    setIsHeroScrolledPast(!inView);

    return () => {
      setIsHeroScrolledPast(true);
    };
  }, [inView, setIsHeroScrolledPast]);

  return (
    <>
      <Seomatic head={Head} {...seomatic} />
      <ShoppingListContainer />

      <div className="mb-20 mt-16 px-8 text-center text-black lg:mb-30">
        <div className="font-spatial text-[22px] font-extralight leading-snug">
          404
        </div>
        <div
          className="mt-4 font-spatial text-5xl font-medium leading-[43px]"
          dangerouslySetInnerHTML={{ __html: title }}
        />
        <div className="my-10 font-sans text-sm font-normal leading-snug">
          {subTitle}
        </div>
        <div className="mx-auto max-w-[660px]" ref={ref}>
          <SearchBar className="max-w-[660px]" theme="light" showArrow />
          <QuerySuggestions
            query=""
            className="mt-4 flex flex-wrap justify-center gap-2"
          >
            {(hits) =>
              hits.map(({ query: term }) => (
                <Chip
                  theme={selectedTerm === term ? "dark" : "light"}
                  onClick={() => toggleTermSelected(term)}
                  className="cursor-pointer capitalize"
                  key={term}
                >
                  {term}
                </Chip>
              ))
            }
          </QuerySuggestions>
        </div>
        <div className="mx-auto my-10 grid max-w-[1440px] grid-cols-2 gap-4 text-left md:grid-cols-3 md:gap-6 xl:grid-cols-4">
          {recipes.map((recipe) => (
            <RecipeThumbnail key={recipe.id} entry={recipe} />
          ))}
        </div>
        <Link href={`/search${selectedTerm ? `?q=${selectedTerm}` : ""}`}>
          <Button className="mx-auto" theme="light">
            View all Recipes
          </Button>
        </Link>
      </div>
    </>
  );
};

export default observer(NotFound);
